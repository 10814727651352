import store from '@/store';
import { Mutations as StoreMutations } from '@/store/enums/StoreEnums';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { capitalize } from '../utils/text';
import {
  ForgotPasswordRouteGuard,
  ProtectedRouteGuard,
  AccountVerificationRouteGuard,
  ProfileRouteGuard,
  SubscriptionRouteGuard,
} from './guards';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'protected-pages',
    redirect: '/sign-in',
    component: () => import('@/views/new-design/LayoutPassThru.vue'),
    beforeEnter: [ProtectedRouteGuard.isLoggedIn()],
    children: [
      {
        path: '',
        name: 'pass-thru-layout',
        redirect: '/dashboard',
        component: () => import('@/views/new-design/Layout.vue'),
        beforeEnter: [ProtectedRouteGuard.main()],
        children: [
          {
            path: '/dashboard',
            name: 'dashboard',
            component: () => import('@/views/new-design/Dashboard.vue'),
          },
          {
            path: '/partners',
            name: 'partners',
            component: () => import('@/views/new-design/Agencies.vue'),
            children: [
              {
                path: '',
                name: 'list-agency',
                component: () =>
                  import('@/views/new-design/pages/Agencies/List.vue'),
              },
            ],
          },
          {
            path: '/promos',
            name: 'promos',
            component: () => import('@/views/new-design/Promos.vue'),
            children: [
              {
                path: '',
                name: 'list-promos',
                component: () =>
                  import('@/views/new-design/pages/Promos/List.vue'),
              },
            ],
          },

          {
            path: '/referrals',
            name: 'referrals',
            component: () => import('@/views/new-design/Referrals.vue'),
            children: [
              {
                path: '',
                name: 'list-referrals',
                component: () =>
                  import('@/views/new-design/pages/Referrals/List.vue'),
              },
            ],
          },
          {
            path: '/profile',
            redirect: '/profile/overview',
            component: () => import('@/views/new-design/Profile.vue'),
            name: 'my-profile-over-view',
            children: [
              {
                path: '/profile/overview',
                component: () =>
                  import('@/views/new-design/pages/Profile/Overview.vue'),
              },
              {
                path: '/profile/business-details',
                component: () =>
                  import(
                    '@/views/new-design/pages/Profile/BusinessDetails.vue'
                  ),
                beforeEnter: [ProfileRouteGuard.isPrincipal()],
              },
              {
                path: '/profile/password-reset',
                component: () =>
                  import('@/views/new-design/pages/Profile/PasswordReset.vue'),
              },
              {
                path: 'subscription',
                name: 'subscription-tab',
                component: () =>
                  import('@/views/new-design/pages/Profile/Subscription.vue'),
              },
              {
                path: 'invoices',
                name: 'invoices-tab',
                component: () =>
                  import('@/views/new-design/pages/Profile/Invoices.vue'),
              },
            ],
            beforeEnter: [ProtectedRouteGuard.main()],
          },
          {
            path: '/verify-account',
            name: 'request-account-verification-mail',
            component: () =>
              import('@/views/new-design/VerifyAccountNotice.vue'),
            beforeEnter: [
              ProtectedRouteGuard.isNotVerified(),
              AccountVerificationRouteGuard.request(),
            ],
          },
          {
            path: '/account/profile/update',
            name: 'complete-account-profile',
            component: () => import('@/views/new-design/CompleteProfile.vue'),
            beforeEnter: [
              ProtectedRouteGuard.isVerified(),
              ProfileRouteGuard.isProfileComplete(),
            ],
          },
          {
            path: '/settings',
            component: () => import('@/views/new-design/Settings.vue'),
            name: 'settings-page',
            beforeEnter: [ProtectedRouteGuard.main()],
            children: [
              {
                path: '',
                name: 'settings-main',
                component: () =>
                  import('@/views/new-design/pages/Settings/Index.vue'),
                beforeEnter: [ProtectedRouteGuard.main()],
              },

              {
                path: 'change-password',
                name: 'password-management',
                component: () =>
                  import(
                    '@/views/new-design/pages/Settings/ChangePassword.vue'
                  ),
                beforeEnter: [ProtectedRouteGuard.main()],
              },
              {
                path: 'subscriptions',
                name: 'subscription-management',
                component: () =>
                  import('@/views/new-design/pages/Settings/Subscriptions.vue'),
              },
            ],
          },
          {
            path: '/plan',
            name: 'plan-page',
            component: () => import('@/views/new-design/pages/Plan/Plan.vue'),
            beforeEnter: [SubscriptionRouteGuard.hasExistingSubscription()],
          },
          {
            path: '/subscription/change',
            name: 'change-subscription-page',
            component: () =>
              import('@/views/new-design/pages/Plan/ChangePlan.vue'),
          },
          {
            path: '/success',
            name: 'checkout-success-noslash-page',
            component: () =>
              import('@/views/new-design/pages/Plan/CheckoutSuccess.vue'),
          },
          {
            path: '/success/',
            name: 'checkout-success--slash-page',
            component: () =>
              import('@/views/new-design/pages/Plan/CheckoutSuccess.vue'),
          },
          {
            path: '/cancel',
            name: 'checkout-cancel-page',
            component: () =>
              import('@/views/new-design/pages/Plan/CheckoutCancel.vue'),
          },
        ],
      },

      {
        path: '/draws',
        name: 'draws',
        component: () => import('@/views/new-design/LayoutDraw.vue'),
        children: [
          {
            path: ':draw_id',
            name: 'view-draw',
            component: () => import('@/views/new-design/pages/Draws/View.vue'),
          },
        ],
      },
    ],
  },

  {
    path: '/sign-in',
    name: 'sign-in',
    component: () => import('@/views/new-design/SignIn.vue'),
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    component: () => import('@/views/new-design/SignUp.vue'),
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/new-design/ForgotPassword.vue'),
  },
  {
    path: '/reset-password',
    name: 'reset-password-page',
    component: () => import('@/views/new-design/ResetPassword.vue'),
    children: [
      {
        path: ':token',
        name: 'verify-reset-password-token',
        component: () => import('@/views/new-design/ResetPassword.vue'),
        beforeEnter: ForgotPasswordRouteGuard,
      },
    ],
  },
  {
    path: '/account',
    name: 'account-layout',
    component: () => import('@/layout/AccountLayout.vue'),
    children: [
      {
        path: 'password-reset',
        name: 'reset-account-password',
        component: () =>
          import('@/components/forms/forgot-password/ResetPasswordForm.vue'),
      },
      {
        path: 'verify',
        name: 'account-verification',
        component: () => import('@/views/new-design/VerifyAccount.vue'),
        children: [
          {
            path: ':token',
            name: 'validate-account-verify-token',
            component: async () => {
              return import('@/views/account/verification/VerifyToken.vue');
            },
            beforeEnter: AccountVerificationRouteGuard.token(),
          },
          {
            path: 'request',
            name: 'request-new-token',
            component: async () => {
              return import('@/views/account/RequestNewToken.vue');
            },
            beforeEnter: AccountVerificationRouteGuard.request(),
          },
          {
            path: 'renew',
            name: 'resend-verify-account-page',
            component: async () => {
              return import('@/views/account/ResendVerificationCode.vue');
            },
          },
        ],
      },
    ],
  },

  {
    path: '/m',
    name: 'referral-pass-page',
    component: () => import('@/views/new-design/ReferralPassThru.vue'),
  },

  {
    path: '/',
    component: () => import('@/views/new-design/Layout.vue'),
    children: [
      {
        path: 'verify',
        name: 'account-verification-notification-page',
        component: () => import('@/views/new-design/Verify.vue'),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: '/404',
    name: '404',
    component: () => import('@/views/error/Error404.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  strict: true,
});

router.beforeEach((to, from, next) => {
  // reset config to initial state
  store.commit(StoreMutations.RESET_LAYOUT_CONFIG);

  // Get the page title from the route's 'name' attribute (if it exists)
  if (typeof to.name === 'string') {
    const title = to.meta?.title as string;
    // If the route has a title, set it as the page title of the document/page
    if (title) {
      const formatted = capitalize(title.replace(/-/g, ' '));

      document.title = `Blue Perks - ${formatted}`;
    } else {
      document.title = 'Blue Perks';
    }
  }

  next();
  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
