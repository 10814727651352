import ApiService from '@/core/services/ApiService';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Getters, Mutations } from '@/store/enums/PlanEnums';
import { PlanModuleStore, StoreError } from '@/models/StoreModel';
import { Plan } from '@/models/PlanModel';

@Module
export default class PlanModule extends VuexModule implements PlanModuleStore {
  actionError: StoreError | null = null;

  listPlans = [];
  plan = {} as unknown as Plan;
  planProduct = null;

  get [Getters.GET_PLANS](): any {
    return this.listPlans;
  }

  get [Getters.GET_PLAN](): any {
    return this.plan;
  }

  get [Getters.GET_PLAN_PRODUCT](): Plan {
    return this.planProduct as unknown as Plan;
  }

  @Mutation
  [Mutations.SET_PLAN](plan) {
    this.plan = plan;
  }

  @Mutation
  [Mutations.SET_PLANS](plans) {
    this.listPlans = plans;
  }

  @Mutation
  [Mutations.SET_PLAN_PRODUCT](product) {
    this.planProduct = product;
  }

  /**
   * Get errors
   * @returns array
   */
  get [Getters.GET_ACTION_ERROR](): StoreError {
    return this.actionError as StoreError;
  }

  @Mutation
  [Mutations.SET_ACTION_ERROR](error) {
    this.actionError = error;
  }

  @Action
  [Actions.FETCH_PLANS]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get('account/plans?returnAll=true')
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PLANS, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_PLAN](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`account/plan?price=${params.id}`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PLAN, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_PLAN_PRODUCT](payload) {
    ApiService.parameters(payload);

    return new Promise<void>((resolve, reject) => {
      ApiService.get('products')
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PLAN_PRODUCT, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response);
          reject();
        });
    });
  }
}
