import { createStore } from 'vuex';
import { config } from 'vuex-module-decorators';

import AuthModule from '@/store/modules/AuthModule';
import UserModule from '@/store/modules/UserModule';
import BodyModule from '@/store/modules/BodyModule';
import BreadcrumbsModule from '@/store/modules/BreadcrumbsModule';
import ConfigModule from '@/store/modules/ConfigModule';
import Resources from '@/store/modules/ResourcesModule';
import SystemsModule from '@/store/modules/SystemsModule';
import VerifyAccountModule from '@/store/modules/VerifyAccountModule';
import PlanModule from '@/store/modules/PlanModule';
import ForgotPasswordModule from '@/store/modules/ForgotPasswordModule';
import TemplatesModule from '@/store/modules/TemplatesModule';
import SubscriptionModule from '@/store/modules/SubscriptionModule';
import InvoiceModule from '@/store/modules/InvoiceModule';
import PaymentModule from '@/store/modules/PaymentModule';
import AgencyModule from '@/store/modules/AgencyModule';
import DrawModule from '@/store/modules/DrawModule';
import PlanBundleModule from '@/store/modules/PlanBundleModule';
import EntryModule from '@/store/modules/EntryModule';
import PromoModule from '@/store/modules/PromoModule';
import PaymentLogModule from '@/store/modules/PaymentLogModule';
import ReferralModule from '@/store/modules/ReferralModule';

config.rawError = true;

const store = createStore({
  modules: {
    Resources,
    BodyModule,
    ConfigModule,
    BreadcrumbsModule,
    UserModule,
    AuthModule,
    ForgotPasswordModule,
    SystemsModule,
    VerifyAccountModule,
    PlanModule,
    TemplatesModule,
    SubscriptionModule,
    InvoiceModule,
    PaymentModule,
    AgencyModule,
    DrawModule,
    PlanBundleModule,
    EntryModule,
    PromoModule,
    PaymentLogModule,
    ReferralModule,
  },
});

export default store;
