import { App } from 'vue';
import { VueReCaptcha } from 'vue-recaptcha-v3';

const siteKey = process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY;
/**
 * Initialize VueReCaptcha
 * @param app vue instance
 */
export function initReCaptcha(app: App<Element>) {
  app.use(VueReCaptcha, {
    siteKey: siteKey,
    loaderOptions: {
      useRecaptchaNet: true,
      autoHideBadge: false,
      renderParameters: {
        siteUrl: process.env.VUE_APP_BASE_URL,
      },
      explicitRenderParameters: {
        badge: 'bottomright',
      },
    },
  });
}
