import moment, { now } from 'moment';

const formats = {
  default: 'YYYY-MM-DD',
  aus: 'DD/MM/YYYY',
  full: 'LL',
};

export const capitalize = (text: string) => {
  return text !== '' ? text.charAt(0).toUpperCase() + text.slice(1) : text;
};

export const formatABN = (abn = '') => {
  if (!abn) return '';

  if (abn.length === 14) return abn;

  const s1 = abn.slice(0, 2);
  const s2 = abn.slice(2, 5);
  const s3 = abn.slice(5, 8);
  const s4 = abn.slice(8);

  return `${s1} ${s2} ${s3} ${s4}`;
};

export const formatACN = (acn = '') => {
  if (!acn) return '';

  if (acn.length === 11) return acn;

  const s1 = acn.slice(0, 3);
  const s2 = acn.slice(3, 6);
  const s3 = acn.slice(6);

  return `${s1} ${s2} ${s3}`;
};

export const ratingFormat = (average) => {
  return parseFloat(average || 0).toFixed(2);
};

export const numberFormat = (number = '0', decimal = 2) => {
  return parseFloat(number).toFixed(decimal);
};

export const fileExtension = (filename) => {
  return filename.split('.').pop();
};

export const removeSpaces = (value) => {
  return value && value != '' ? value.trim().replace(/\s/g, '') : '';
};

export const acronym = (string, string2 = '') => {
  return typeof string !== 'undefined'
    ? string
        .split(/\s/)
        .reduce((accumulator, word) => accumulator + word.charAt(0), '')
        .toUpperCase()
    : '';
};

export const formatMobile = (mobile) => {
  mobile = mobile ? mobile.trim().replace(' ', '') : '';

  if (!mobile) return '';

  const s1 = mobile.slice(0, 4);
  const s2 = mobile.slice(4, 7);
  const s3 = mobile.slice(7);

  return `${s1} ${s2} ${s3}`;
};

export const formatMoney = (amount, format = 'en-AU', currency = 'AUD') => {
  return new Intl.NumberFormat(format, {
    style: 'currency',
    currency: currency,
  }).format(amount);
};

export const formatDate = (date: string, format = 'aus') => {
  if (!date) return '';

  return moment(date).format(formats[format]);
};

export const formatTime = (time, format = 'hh:mm A') => {
  const dt = now() + time + ':00';
  console.log(dt);
  return moment(dt).format(format);
};

export default {
  capitalize,
  formatABN,
  formatACN,
  ratingFormat,
  numberFormat,
  fileExtension,
  removeSpaces,
  acronym,
  formatMobile,
  formatMoney,
  formatDate,
  formatTime,
};
