import Swal from 'sweetalert2';
import { formDataErrors } from '@/utils/forms';

const toasts = {
  alert: (text: string) => {
    Swal.fire({
      text: text,
      icon: 'info',
      buttonsStyling: false,
      confirmButtonText: 'Ok',
      customClass: {
        confirmButton: 'btn fw-bold btn-light-primary',
      },
    });
  },

  success: (text: string, closure = (() => undefined) as any) => {
    Swal.fire({
      text: text,
      icon: 'success',
      buttonsStyling: false,
      confirmButtonText: 'Ok',
      customClass: {
        confirmButton: 'btn fw-bold btn-light-primary',
      },
    }).then(closure);
  },

  error: (errors, message, closure = (() => undefined) as any) => {
    return Swal.fire({
      html: formDataErrors(errors, message),
      icon: 'error',
      buttonsStyling: false,
      confirmButtonText: 'Try again!',
      customClass: {
        confirmButton: 'btn fw-bold btn-light-danger',
      },
    }).then(closure);
  },

  confirm: (text, closure = (() => undefined) as any) => {
    Swal.fire({
      text: text,
      icon: 'warning',
      buttonsStyling: false,
      confirmButtonText: 'Yes',
      showCancelButton: true,
      allowOutsideClick: false,
      customClass: {
        confirmButton: 'btn fw-bold btn-light-primary',
        cancelButton: 'btn btn-new-design-secondary ml-1',
      },
    }).then(closure);
  },

  prompt: (text, closure = (() => undefined) as any) => {
    Swal.fire({
      text: text,
      icon: 'success',
      buttonsStyling: false,
      confirmButtonText: 'Ok',
      customClass: {
        confirmButton: 'btn fw-bold btn-light-primary',
      },
    }).then(closure);
  },

  delete: (text, closure = (() => undefined) as any) => {
    return Swal.fire({
      text: text,
      icon: 'warning',
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      customClass: {
        confirmButton: 'btn fw-bold btn-danger',
        cancelButton: 'btn fw-bold btn-secondary',
      },
    }).then((closure) => {
      return closure;
    });
  },

  message: (text, closure = (() => undefined) as any) => {
    return Swal.fire({
      text: text,
      icon: 'warning',
      input: 'textarea',
      inputAttributes: {
        autocapitalize: 'off',
      },
      showLoaderOnConfirm: true,
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      customClass: {
        confirmButton: 'btn fw-bold btn-danger',
        cancelButton: 'btn fw-bold btn-secondary',
      },
      preConfirm: (data) => {
        if (!data) return Swal.showValidationMessage(`Please enter remarks`);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((closure) => {
      return closure;
    });
  },
};

export default toasts;
