import ApiService from '@/core/services/ApiService';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Getters, Mutations } from '@/store/enums/PromoEnums';
import { PromoModuleStore, StoreError } from '@/models/StoreModel';
import { Promo } from '@/models/PromoModel';

@Module
export default class PromoModule
  extends VuexModule
  implements PromoModuleStore
{
  actionError: StoreError | null = null;

  allPromos = [];
  promos = [];
  promo = null;

  get [Getters.GET_ALL_PROMOS](): Promo[] {
    return this.allPromos as unknown as Promo[];
  }

  get [Getters.GET_PROMO](): Promo {
    return this.promo as unknown as Promo;
  }

  get [Getters.GET_PROMOS](): Promo[] {
    return this.promos as unknown as Promo[];
  }

  get [Getters.GET_ACTION_ERROR](): StoreError {
    return this.actionError as StoreError;
  }

  @Mutation
  [Mutations.SET_PROMO](promo) {
    this.promo = promo;
  }

  @Mutation
  [Mutations.SET_PROMOS](promos) {
    this.promos = promos;
  }

  @Mutation
  [Mutations.SET_ALL_PROMOS](promos) {
    this.promos = promos;
  }

  @Mutation
  [Mutations.SET_ACTION_ERROR](error) {
    this.actionError = error;
  }

  @Action
  [Actions.FETCH_PROMOS]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get('promos')
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PROMOS, data.data.promos);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response);
          reject();
        });
    });
  }
}
