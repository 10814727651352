import ApiService from '@/core/services/ApiService';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Getters, Mutations } from '@/store/enums/PlanBundleEnums';
import { PlanBundleModuleStore, StoreError } from '@/models/StoreModel';
import { PlanBundle } from '@/models/PlanBundleModel';
import { ResourcePagination } from '@/models/CommonModel';

@Module
export default class PlanBundleModule
  extends VuexModule
  implements PlanBundleModuleStore
{
  actionError: StoreError | null = null;
  listError: StoreError | null = null;

  allPlanBundles = [];
  planBundle = null;
  planBundles = [];

  listLoading = false;

  pagination = null as unknown as ResourcePagination;

  get [Getters.GET_PAGINATION](): ResourcePagination {
    return this.pagination;
  }

  get [Getters.GET_PLAN_BUNDLE](): PlanBundle {
    return this.planBundle as unknown as PlanBundle;
  }

  get [Getters.GET_PLAN_BUNDLES](): PlanBundle {
    return this.planBundles as unknown as PlanBundle;
  }

  get [Getters.GET_ACTION_ERROR](): StoreError {
    return this.actionError as unknown as StoreError;
  }

  @Mutation
  [Mutations.SET_PAGINATION](pagination) {
    this.pagination = pagination;
  }

  @Mutation
  [Mutations.SET_PLAN_BUNDLES](bundles) {
    this.planBundles = bundles;
  }

  @Mutation
  [Mutations.SET_PLAN_BUNDLE](bundle) {
    this.planBundle = bundle;
  }

  @Mutation
  [Mutations.SET_ACTION_ERROR](error) {
    this.actionError = error;
  }

  @Action
  [Actions.FETCH_PLAN_BUNDLES](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`draws/${params.drawId}/bundles`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PLAN_BUNDLES, data.data.bundles);
          this.context.commit(Mutations.SET_PAGINATION, data.data.pagination);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_PLAN_BUNDLE](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`draws/${params.drawId}/bundles/${params.bundleId}`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PLAN_BUNDLES, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response);
          reject();
        });
    });
  }

  @Action
  [Actions.STORE_PLAN_BUNDLES](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`draws/${payload.drawId}/bundles`, payload.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_PLAN_BUNDLES](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put(
        `draws/${payload.drawId}/bundles/${payload.bundleId}`,
        payload.data
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_PLAN_BUNDLES](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`draws/${params.drawId}/bundles/${params.id}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response);
          reject();
        });
    });
  }
}
