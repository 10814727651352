import { App } from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import JwtService from '@/core/services/JwtService';
import { AxiosResponse, AxiosRequestConfig } from 'axios';
import CookieService from './CookieService';

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;
  public static _token;

  public static params = {};
  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService._token = JwtService.getToken() as unknown;

    if (!JwtService.getToken() && CookieService.get('sessionToken')) {
      ApiService._token = CookieService.get('sessionToken');
    }

    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.setBaseUrl();
    ApiService.setAuthorization(ApiService._token);
  }

  /**
   * Return bearer token
   * */

  public static setBaseUrl() {
    ApiService.vueInstance.axios.defaults.baseURL =
      process.env.VUE_APP_API_BASE_URL;
  }

  /**
   * Return bearer token
   * */

  public static setAuthorization(sessionToken: string | unknown) {
    if (!sessionToken) return;

    ApiService.vueInstance.axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${sessionToken}`;
  }

  /**
   * Return common header
   * */

  public static setCommonHeader(headers = {}) {
    for (const header in headers) {
      if (!header) continue;

      ApiService.vueInstance.axios.defaults.headers.common[header] =
        headers[header];
    }
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setMulitpartHeader(isMultipart = false as boolean): void {
    if (isMultipart) {
      ApiService.vueInstance.axios.defaults.headers.common['Content-Type'] =
        'multipart/form-data';
    }
  }

  /**
   * @description build and set params for GET
   */
  public static parameters(payload = {}): void {
    ApiService.params = {};
    if (payload) {
      const filteredPayload = Object.entries(payload).filter(
        ([key, value]) => typeof key !== 'undefined'
      );
      if (filteredPayload.length) {
        ApiService.params = {
          params: Object.fromEntries(filteredPayload),
        };
      }
    }
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    config: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, config).catch((error) => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = '' as string,
    config = {} as AxiosRequestConfig
  ): Promise<AxiosResponse> {
    const endpoint = slug != '' ? `${resource}/${slug}` : resource;

    let requestConfig = {} as AxiosRequestConfig;

    if (Object.entries(ApiService.params).length !== 0) {
      requestConfig = {
        ...requestConfig,
        ...ApiService.params,
      };
    }

    if (Object.entries(config).length !== 0) {
      requestConfig = {
        ...requestConfig,
        ...config,
      };
    }

    return ApiService.vueInstance.axios
      .get(`${endpoint}`, requestConfig)
      .then((response) => {
        // Reset parameters
        ApiService.parameters();
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: AxiosRequestConfig | FormData = {},
    config = {} as AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params, config);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
}

export default ApiService;
