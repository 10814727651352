import ApiService from '@/core/services/ApiService';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

import { Actions, Getters, Mutations } from '@/store/enums/InvoiceEnums';

import {
  StoreError,
  SystemError,
  InvoiceModuleStore,
} from '@/models/StoreModel';
import { Invoice } from '@/models/InvoiceModel';
import { UpcomingInvoice } from '@/models/UpcomingInvoiceModel';
import { PaymentMethod } from '@/models/PaymentMethodModel';

@Module
export default class InvoiceModule
  extends VuexModule
  implements InvoiceModuleStore
{
  actionError: StoreError | null = null;

  allInvoices = [];
  invoice = {} as unknown as Invoice;
  invoices = [];

  upcomingInvoice = {} as unknown as UpcomingInvoice;

  paymentMethod = {} as unknown as PaymentMethod;

  get [Getters.GET_INVOICES](): Invoice[] {
    return this.invoices;
  }

  get [Getters.GET_INVOICE](): Invoice {
    return this.invoice;
  }

  get [Getters.GET_UPCOMING_INVOICE](): UpcomingInvoice {
    return this.upcomingInvoice as unknown as UpcomingInvoice;
  }

  get [Getters.GET_PAYMENT_METHOD](): PaymentMethod {
    return this.paymentMethod as unknown as PaymentMethod;
  }

  get [Getters.GET_ACTION_ERROR](): StoreError {
    return this.actionError as StoreError;
  }

  @Mutation
  [Mutations.SET_INVOICES](invoices) {
    this.invoices = invoices;
  }

  @Mutation
  [Mutations.SET_INVOICE](invoice) {
    this.invoice = invoice;
  }

  @Mutation
  [Mutations.SET_UPCOMING_INVOICE](invoice) {
    this.upcomingInvoice = invoice;
  }

  @Mutation
  [Mutations.SET_PAYMENT_METHOD](method) {
    this.paymentMethod = method;
  }

  @Mutation
  [Mutations.SET_ACTION_ERROR](error) {
    this.actionError = error;
  }

  @Action
  [Actions.FETCH_INVOICES](payload) {
    ApiService.parameters();

    return new Promise<void>((resolve, reject) => {
      ApiService.get('invoices')
        .then(({ data }) => {
          this.context.commit(Mutations.SET_INVOICES, data.data);
          resolve();
        })
        .catch(({ response }) => {
          console.log('response', response);
          this.context.commit(Mutations.SET_ACTION_ERROR, response);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_UPCOMING_INVOICE]() {
    ApiService.parameters();

    return new Promise<void>((resolve, reject) => {
      ApiService.get('invoices/upcoming')
        .then(({ data }) => {
          this.context.commit(Mutations.SET_UPCOMING_INVOICE, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_PAYMENT_METHOD](payload) {
    ApiService.parameters(payload);

    return new Promise<void>((resolve, reject) => {
      ApiService.get('payment_methods')
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PAYMENT_METHOD, data.data);
          resolve();
        })
        .catch(({ response }) => {
          console.log('response', response);
          this.context.commit(Mutations.SET_ACTION_ERROR, response);
          reject();
        });
    });
  }
}
