import ApiService from '@/core/services/ApiService';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Getters, Mutations } from '@/store/enums/DrawEnums';
import { DrawModuleStore, StoreError } from '@/models/StoreModel';
import { Draw } from '@/models/DrawModel';

@Module
export default class DrawModule extends VuexModule implements DrawModuleStore {
  actionError: StoreError | null = null;
  dataError: StoreError | null = null;

  draw = null;
  upcomingDraws = [];
  previousDraws = [];

  get [Getters.GET_UPCOMING_DRAWS](): Draw[] {
    return this.upcomingDraws;
  }

  get [Getters.GET_PREVIOUS_DRAWS](): Draw[] {
    return this.previousDraws;
  }

  get [Getters.GET_DATA_ERRORS](): StoreError {
    return this.actionError as StoreError;
  }

  get [Getters.GET_ACTION_ERRORS](): StoreError {
    return this.actionError as StoreError;
  }

  get [Getters.GET_DRAW]() {
    return this.draw;
  }

  @Mutation
  [Mutations.SET_UPCOMING_DRAWS](draws) {
    this.upcomingDraws = draws;
  }

  @Mutation
  [Mutations.SET_PREVIOUS_DRAWS](draws) {
    this.previousDraws = draws;
  }

  @Mutation
  [Mutations.SET_DATA_ERRORS](error) {
    this.dataError = error;
  }

  @Mutation
  [Mutations.SET_ACTION_ERRORS](error) {
    this.actionError = error;
  }

  @Mutation
  [Mutations.SET_DRAW](draw) {
    this.draw = draw;
  }

  @Action
  [Actions.FETCH_UPCOMING_DRAWS]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`draws/upcoming`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_UPCOMING_DRAWS, data.data.draws);
          resolve();
        })
        .catch((response) => {
          this.context.commit(Mutations.SET_DATA_ERRORS, response);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_PREVIOUS_DRAWS]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`draws/previous`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PREVIOUS_DRAWS, data.data.draws);
          resolve();
        })
        .catch((response) => {
          this.context.commit(Mutations.SET_DATA_ERRORS, response);
          reject();
        });
    });
  }

  @Action
  [Actions.ADD_DRAW_ENTRIES](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`draws/entries`, payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PREVIOUS_DRAWS, data.data.draws);
          resolve();
        })
        .catch((response) => {
          this.context.commit(Mutations.SET_DATA_ERRORS, response);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_DRAW](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`draws/${id}`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_DRAW, data.data);
          resolve();
        })
        .catch((response) => {
          this.context.commit(Mutations.SET_DATA_ERRORS, response);
          reject();
        });
    });
  }
}
