import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

import ApiService from '@/core/services/ApiService';
import { SystemsModuleStore, StoreError } from '@/models/StoreModel';
import { Actions, Mutations, Getters } from '@/store/enums/SystemEnums';
import { AccessibleObject } from '@/models/CommonModel';

@Module
export default class SystemsModule
  extends VuexModule
  implements SystemsModuleStore
{
  allCountries: AccessibleObject[] = [];
  allTimezones: AccessibleObject[] = [];
  states: AccessibleObject[] = [];

  countriesListError: StoreError | null = null;
  timezonesListError: StoreError | null = null;
  statesListError: StoreError | null = null;

  /**
   * Returns the list of all countries
   * @returns AccessibleObject[]
   */
  get [Getters.GET_ALL_COUNTRIES](): AccessibleObject[] {
    return this.allCountries as unknown as AccessibleObject[];
  }

  /**
   * Returns the list of all timezones
   * @returns AccessibleObject[]
   */
  get [Getters.GET_ALL_TIMEZONES](): AccessibleObject[] {
    return this.allTimezones as unknown as AccessibleObject[];
  }

  get [Getters.GET_LIST_STATES](): AccessibleObject[] {
    return this.states as unknown as AccessibleObject[];
  }

  @Mutation
  [Mutations.SET_LIST_STATES](states: AccessibleObject[]) {
    this.states = states;
  }

  @Mutation
  [Mutations.SET_ALL_COUNTRIES](allCountries: AccessibleObject[]) {
    this.allCountries = allCountries;
  }

  @Mutation
  [Mutations.SET_ALL_TIMEZONES](allTimezones: AccessibleObject[]) {
    this.allTimezones = allTimezones;
  }

  @Action({ rawError: true })
  [Actions.LIST_ALL_COUNTRIES](forceCall: boolean) {
    const allCountries = this.context.getters[Getters.GET_ALL_COUNTRIES];

    // if not an explicit refresh call
    if (!forceCall && allCountries.length) return Promise.resolve();

    return ApiService.query('countries', {
      params: { returnAll: true },
    })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_COUNTRIES, data.data);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  @Action({ rawError: true })
  [Actions.LIST_ALL_TIMEZONES](forceCall: boolean) {
    const allTimezones = this.context.getters[Getters.GET_ALL_TIMEZONES];

    // if not an explicit refresh call
    // and allJobTypes is not empty
    if (!forceCall && allTimezones.length) return Promise.resolve();

    return ApiService.query('timezones', {
      params: { returnAll: true },
    })
      .then(({ data }) => {
        const timezoneData = data.data.map(({ label }) => {
          const parsedLabel = label.replace(/_/g, ' ');

          return {
            label: parsedLabel,
            value: parsedLabel.split(') ')[1],
          };
        });

        this.context.commit(Mutations.SET_ALL_TIMEZONES, timezoneData);

        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  @Action({ rawError: true })
  [Actions.LIST_STATES](CountryCode) {
    if (!CountryCode) return Promise.resolve();

    return ApiService.get(`countries/${CountryCode}/states`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_LIST_STATES, data.data);

        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}
