enum Actions {
  ADD_DRAW_ENTRIES = 'addEntries',
  FETCH_DRAW_ENTRIES = 'fetchDrawEntries',
  FETCH_USER_ENTRIES = 'fetchUserEntries',
}

enum Mutations {
  SET_ALL_ENTRIES = 'setAllEntries',
  SET_DRAW_ENTRY = 'setDrawEntry',
  SET_DRAW_ENTRIES = 'setDrawEntries',
  SET_ACTION_ERRORS = 'setEntryActionErrors',
}

enum Getters {
  GET_ALL_ENTRIES = 'getAllEntries',
  GET_DRAW_ENTRY = 'getDrawEntry',
  GET_DRAW_ENTRIES = 'getDrawEntries',
  GET_ACTION_ERRORS = 'getEntryActionErrors',
}

export { Actions, Mutations, Getters };
