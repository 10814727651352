enum Actions {
  FETCH_PROMOS = 'fetchPromos',
}

enum Mutations {
  SET_PROMO = 'setPromo',
  SET_PROMOS = 'setPromos',
  SET_ALL_PROMOS = 'setAllPromos',
  SET_ACTION_ERROR = 'setPromoActionError',
}

enum Getters {
  GET_PROMO = 'getPromo',
  GET_PROMOS = 'getPromos',
  GET_ALL_PROMOS = 'getAllPromos',
  GET_ACTION_ERROR = 'getPromoActionError',
}

export { Actions, Mutations, Getters };
