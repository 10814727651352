import { App } from 'vue';
import { createGtm } from 'vue-gtm';

export async function initGTMSupport(app: App<Element>, router) {
  const gtmID = process.env.VUE_APP_GOOGLE_TAG_MANAGER_KEY as string;

  const createGTMLink = createGtm({
    id: gtmID,
    defer: false,
    compatibility: true,
    enabled: true,
    debug: true,
    loadScript: true,
    vueRouter: router,
    trackOnNextTick: false,
  });

  return app.use(createGTMLink);
}
