enum Actions {
  FETCH_PLAN_BUNDLES = 'fetchPlanBundles',
  FETCH_PLAN_BUNDLE = 'fetchPlanBundle',
  STORE_PLAN_BUNDLES = 'storePlanBundles',
  UPDATE_PLAN_BUNDLES = 'updatePlanBundles',
  DELETE_PLAN_BUNDLES = 'deletePlanBundle',
}

enum Mutations {
  SET_PLAN_BUNDLE = 'setPlanBundle',
  SET_PLAN_BUNDLES = 'setPlanBundles',
  SET_PAGINATION = 'setPlanBundlePagination',
  SET_ACTION_ERROR = 'setBundleActionError',
}

enum Getters {
  GET_PLAN_BUNDLE = 'getPlanBundle',
  GET_PLAN_BUNDLES = 'getPlanBundles',
  GET_PAGINATION = 'getPlanBundlePagination',
  GET_ACTION_ERROR = 'getBundleActionError',
}

export { Actions, Mutations, Getters };
