import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/ResourcesEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { get, each } from 'lodash';

type OptionItem = Array<{ value: string; label: string; key?: string }>;

export interface ResourcesInfo {
  countries: OptionItem;
  states: OptionItem;
}

export type OptionRoles = Array<{ value: string; key: string }>;

@Module
// eslint-disable-next-line prettier/prettier
export default class ResourcesModule
  extends VuexModule
  implements ResourcesInfo
{
  countries = [] as OptionItem;
  states = [] as OptionItem;
  roles = [] as OptionRoles;

  get countryOptions(): OptionItem {
    return this.countries;
  }

  get stateOptions(): OptionItem {
    return this.states;
  }

  get ratingText(): Array<string> {
    return ['Very bad', 'Disappointed', 'Fair', 'Good', 'Very Good'];
  }

  get roleOptions(): OptionRoles {
    return this.roles;
  }

  @Mutation
  [Mutations.SET_COUNTRY](payload): void {
    this.countries = payload;
  }

  @Mutation
  [Mutations.SET_STATE](payload): void {
    this.states = [];
    if (typeof payload === 'object') {
      each(payload, (value, key) => {
        this.states.push({ label: value, value, key });
      });
    } else {
      this.states = payload;
    }
  }

  @Mutation
  [Mutations.SET_ROLES](payload): void {
    this.roles = payload;
  }

  @Action
  [Actions.DISABLE_DATES_TOMORROW](time: Date) {
    return time.getTime() > Date.now();
  }

  @Action
  [Actions.DISABLE_DATES_YESTERDAY](time: Date) {
    return time.getTime() < Date.now();
  }

  @Action
  [Actions.FETCH_COUNTRIES]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get('countries?returnAll=true')
        .then((response) => {
          this.context.commit(
            Mutations.SET_COUNTRY,
            get(response, 'data.data', [])
          );
          resolve();
        })
        .then(() => {
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_STATE](country = 'AU') {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`countries/${country}/states`)
        .then((response) => {
          const payload = get(response, 'data.data', []);
          this.context.commit(Mutations.SET_STATE, payload);
          resolve();
        })
        .then(() => {
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_ROLES]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get('admin/roles')
        .then((response) => {
          this.context.commit(
            Mutations.SET_ROLES,
            get(response, 'data.data.roles')
          );
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }
}
