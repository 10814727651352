enum Actions {
  FETCH_AGENCIES = 'fetchAgencies',
  FETCH_SINGLE_AGENCY = 'fetchSingleAgency',
  FETCH_AGENCY_USERS = 'fetchUsers',
  FETCH_REFERENCES = 'fetchReferences',
}

enum Mutations {
  SET_AGENCIES = 'setAgencies',
  SET_SINGLE_AGENCY = 'setSingleAgency',
  SET_AGENCY_USERS = 'setAgencyUsers',
  SET_ERRORS = 'setAgencyErrors',
  SET_REFERENCES = 'setReferences',
}

enum Getters {
  GET_AGENCIES = 'getAgencies',
  GET_SINGLE_AGENCY = 'getSingleAgency',
  GET_AGENCY_USERS = 'getAgencyUsers',
  GET_ERRORS = 'getAgencyErrors',
  GET_REFERENCES = 'getReferences',
}

export { Actions, Mutations, Getters };
