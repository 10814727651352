import ApiService from '@/core/services/ApiService';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Getters, Mutations } from '@/store/enums/PaymentLogEnums';
import { PaymentLogModuleStore, StoreError } from '@/models/StoreModel';
import { PaymentLog } from '@/models/PaymentLogModel';
import { ResourcePagination } from '@/models/CommonModel';

@Module
export default class PaymentLogModule
  extends VuexModule
  implements PaymentLogModuleStore
{
  actionError: StoreError | null = null;

  paymentLogs = [];

  pagination = null as unknown as ResourcePagination;

  get [Getters.GET_PAGINATION](): ResourcePagination {
    return this.pagination;
  }

  get [Getters.GET_PAYMENT_LOGS](): PaymentLog[] {
    return this.paymentLogs as unknown as PaymentLog[];
  }

  get [Getters.GET_ACTION_ERROR](): StoreError {
    return this.actionError as StoreError;
  }

  @Mutation
  [Mutations.SET_PAYMENT_LOGS](logs) {
    this.paymentLogs = logs;
  }

  @Mutation
  [Mutations.SET_ACTION_ERROR](error) {
    this.actionError = error;
  }

  @Mutation
  [Mutations.SET_PAGINATION](pagination) {
    this.pagination = pagination;
  }

  @Action
  [Actions.FETCH_PAYMENT_LOGS]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get('transactions')
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PAYMENT_LOGS, data.data.logs);
          this.context.commit(Mutations.SET_PAGINATION, data.data.pagination);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response);
          reject();
        });
    });
  }
}
