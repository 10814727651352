import ApiService from '@/core/services/ApiService';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Getters, Mutations } from '@/store/enums/ReferralEnums';
import { ReferralModuleStore, StoreError } from '@/models/StoreModel';
import { Referral } from '@/models/ReferralModel';
import { ResourcePagination } from '@/models/CommonModel';

@Module
export default class ReferralModule
  extends VuexModule
  implements ReferralModuleStore
{
  actionError: StoreError | null = null;
  referrals = [] as unknown as Referral[];
  referral = {} as unknown as Referral;

  pagination = null as unknown as ResourcePagination;

  get [Getters.GET_REFERRALS](): any {
    return this.referrals;
  }

  get [Getters.GET_ACTION_ERROR](): StoreError {
    return this.actionError as StoreError;
  }

  @Mutation
  [Mutations.SET_REFERRALS](referrals) {
    this.referrals = referrals;
  }

  @Mutation
  [Mutations.SET_ACTION_ERROR](errors) {
    this.actionError = errors;
  }

  @Action
  [Actions.FETCH_REFERRALS]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`referrals?returnAll=true`)
        .then((response) => {
          this.context.commit(Mutations.SET_REFERRALS, response.data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }
}
