enum Actions {
  FETCH_COUNTRIES = 'fetchCountries',
  FETCH_STATE = 'fetchState',
  FETCH_ROLES = 'fetchRoles',
  DISABLE_DATES_YESTERDAY = 'disbaleDatesYesterday',
  DISABLE_DATES_TOMORROW = 'disbaleDatesTomorrow',
}

enum Mutations {
  SET_COUNTRY = 'setCountry',
  SET_STATE = 'setState',
  SET_ROLES = 'setRoles',
}

export { Actions, Mutations };
