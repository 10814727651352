enum Actions {
  FETCH_INVOICES = 'fetchInvoices',
  FETCH_INVOICE = 'fetchInvoiceById',
  FETCH_UPCOMING_INVOICE = 'fetchUpcomingInvoice',
  FETCH_PAYMENT_METHOD = 'fetchPaymentMethod',
}

enum Mutations {
  SET_INVOICES = 'setInvoices',
  SET_INVOICE = 'setInvoice',
  SET_UPCOMING_INVOICE = 'setUpcomingInvoice',
  SET_PAYMENT_METHOD = 'setPaymentMethod',
  SET_ACTION_ERROR = 'setInvoicesActionError',
}

enum Getters {
  GET_INVOICES = 'getInvoices',
  GET_INVOICE = 'getInvoice',
  GET_UPCOMING_INVOICE = 'getUpcomingInvoice',
  GET_PAYMENT_METHOD = 'getPaymentMethod',
  GET_ACTION_ERROR = 'getInvoicesActionError',
}

export { Actions, Mutations, Getters };
