import store from '@/store';
import { Actions } from '@/store/enums/ForgotPasswordEnums';

const forgotPassword = async (to) => {
  let isAllGood = false as string | boolean;

  if (!to.params.token) {
    return 'password-reset';
  }

  await store
    .dispatch(Actions.FORGOT_PASSWORD_VERIFY, {
      token: to.params.token,
    })
    .then(() => {
      isAllGood = true;
    })
    .catch(() => {
      isAllGood = 'forgot-password';
    });
  return isAllGood;
};

export default forgotPassword;
