import ApiService from '@/core/services/ApiService';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Getters, Mutations } from '@/store/enums/EntryEnums';
import { EntryModuleStore, StoreError } from '@/models/StoreModel';
import { Entry } from '@/models/EntryModel';

@Module
export default class EntrysModule
  extends VuexModule
  implements EntryModuleStore
{
  actionError: StoreError | null = null;
  dataError: StoreError | null = null;

  allEntries = [] as unknown as Entry[];
  entries = [] as unknown as Entry[];
  entry = null;

  get [Getters.GET_ALL_ENTRIES](): Entry[] {
    return this.allEntries;
  }

  get [Getters.GET_DRAW_ENTRIES](): Entry[] {
    return this.entries;
  }

  get [Getters.GET_ACTION_ERRORS](): StoreError {
    return this.actionError as StoreError;
  }

  @Mutation
  [Mutations.SET_ALL_ENTRIES](entries) {
    this.allEntries = entries;
  }

  @Mutation
  [Mutations.SET_DRAW_ENTRY](entries) {
    this.entries = entries;
  }

  @Mutation
  [Mutations.SET_ACTION_ERRORS](error) {
    this.actionError = error;
  }

  @Action
  [Actions.FETCH_USER_ENTRIES]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`entries?returnAll=true`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ALL_ENTRIES, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERRORS, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_DRAW_ENTRIES](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`draws/${payload.id}/entries`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_DRAW_ENTRY, data.data.entries);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERRORS, response.data);
          reject();
        });
    });
  }
}
