enum Actions {
  FETCH_PAYMENT_LOGS = 'fetchPaymentLogs',
}

enum Mutations {
  SET_PAYMENT_LOGS = 'setPaymentLogs',
  SET_ACTION_ERROR = 'setPaymentLogsActionError',
  SET_PAGINATION = 'setPaymentLogsPagination',
}

enum Getters {
  GET_PAYMENT_LOGS = 'getPaymentLogs',
  GET_ACTION_ERROR = 'getPaymentLogsActionError',
  GET_PAGINATION = 'getPaymentLogsPagination',
}

export { Actions, Mutations, Getters };
