import * as smartsupp from 'smartsupp-widget';
import { App } from 'vue';

export function initSmartSupp(app: App) {
  smartsupp.init('221d612cc50c173429e85fee787692b316b5339f', {
    cookieDomain: 'app.blueperks.au',
    offsetY: 90,
  });
  app.component('smartsupp', smartsupp);
}
