import ApiService from '@/core/services/ApiService';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Getters, Mutations } from '@/store/enums/AgencyEnums';
import { AxiosRequestConfig } from 'axios';
import { AgencyModuleStore, StoreError } from '@/models/StoreModel';

@Module
export default class AgencyModule
  extends VuexModule
  implements AgencyModuleStore
{
  actionError: StoreError | null = null;
  agencies = [];
  agency = null;
  users = [];

  get [Getters.GET_AGENCIES](): any {
    return this.agencies;
  }

  get [Getters.GET_SINGLE_AGENCY](): any {
    return this.agency;
  }

  get [Getters.GET_AGENCY_USERS](): any {
    return this.users;
  }

  get [Getters.GET_ERRORS](): StoreError {
    return this.actionError as StoreError;
  }

  @Mutation
  [Mutations.SET_AGENCIES](agencies) {
    this.agencies = agencies;
  }

  @Mutation
  [Mutations.SET_SINGLE_AGENCY](agency) {
    this.agency = agency;
  }

  @Mutation
  [Mutations.SET_AGENCY_USERS](users) {
    this.users = users;
  }

  @Mutation
  [Mutations.SET_ERRORS](errors) {
    this.actionError = errors;
  }

  @Action
  [Actions.FETCH_AGENCIES]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`agencies`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AGENCIES, data.data.agencies);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERRORS, response);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_SINGLE_AGENCY](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/agencies/${params.id}`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SINGLE_AGENCY, data.agencies);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERRORS, response);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_AGENCY_USERS](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`agencies/${params.id}/users`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AGENCY_USERS, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERRORS, response);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_REFERENCES](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`agencies/${params.id}/references`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_REFERENCES, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERRORS, response);
          reject();
        });
    });
  }
}
