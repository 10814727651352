enum Actions {
  FETCH_REFERRALS = 'fetchReferrals',
}

enum Mutations {
  SET_REFERRALS = 'setReferrals',
  SET_ACTION_ERROR = 'setReferralErrors',
}

enum Getters {
  GET_REFERRALS = 'getReferrals',
  GET_ACTION_ERROR = 'getReferralErrors',
}

export { Actions, Mutations, Getters };
