enum Actions {
  FETCH_UPCOMING_DRAWS = 'fetchUpcomingDraws',
  FETCH_PREVIOUS_DRAWS = 'fetchPreviousDraws',
  ADD_DRAW_ENTRIES = 'addDrawEntries',
  FETCH_DRAW = 'fetchDraw',
}

enum Mutations {
  SET_DRAW = 'setDraw',
  SET_UPCOMING_DRAWS = 'setUpcomingDraws',
  SET_PREVIOUS_DRAWS = 'setPreviousDraws',
  SET_DATA_ERRORS = 'setDrawDataErrors',
  SET_ACTION_ERRORS = 'setDrawActionErrors',
}

enum Getters {
  GET_DRAW = 'getDraw',
  GET_UPCOMING_DRAWS = 'getUpcomingDraws',
  GET_PREVIOUS_DRAWS = 'getPreviousDraws',
  GET_DATA_ERRORS = 'getDrawDataErrors',
  GET_ACTION_ERRORS = 'getDrawActionErrors',
}

export { Actions, Mutations, Getters };
