import CookieService from '@/core/services/CookieService';
import store from '@/store';
import { Getters as AuthGetters } from '@/store/enums/AuthEnums';

const SubscriptionRouteGuard = {
  hasSubscription: () => async (to, from, next) => {
    // bypass requested tenant reference submission
    // const referenceRequest = await store.getters[
    //   RRGetters.GET_CURRENT_REFERENCE_REQUEST
    // ];
    // const ref = localStorage.getItem('reference_request');
    // if (to.name === 'submit-tenant-review' && to.query.ref === ref) {
    //   return next();
    // }

    //if the auth user has subscription
    const user = await store.getters[AuthGetters.GET_AUTH_USER];

    if (
      typeof user.subscription === 'undefined' ||
      user.subscription.length === 0
    ) {
      return next({ name: 'plan-page' });
    }

    const hasActiveSubscription =
      typeof user?.subscription !== 'undefined'
        ? user?.subscription.filter((subscription: any) => {
            return (
              subscription?.stripe_status === 'active' ||
              subscription?.stripe_status === 'trialing'
            );
          })
        : 0;

    if (!hasActiveSubscription.length) {
      return next({ name: 'plan-page' });
    }

    next();
  },

  hasExistingSubscription: () => async (to, from, next) => {
    //if the auth user has subscription
    const user = await store.getters[AuthGetters.GET_AUTH_USER];

    const hasActiveSubscription =
      typeof user?.subscription !== 'undefined'
        ? user.subscription.filter((subscription: any) => {
            return (
              subscription?.stripe_status === 'active' ||
              subscription?.stripe_status === 'trialing'
            );
          })
        : 0;

    if (hasActiveSubscription.length) {
      return next({ name: 'subscription-tab' });
    }

    next();
  },

  toCheckoutPremium: () => async (to, from, next) => {
    if ((await to.query.subscribe) === 'premium') {
      CookieService.set('___bp_subscribe_to', 'premium');

      if ((await to.query.tf) !== '') {
        CookieService.set('___bp_subscribe_tf', await to.query.tf);
      }
    } else {
      CookieService.remove('___bp_subscribe_to');
      CookieService.remove('___bp_subscribe_tf');
    }
    return next();
  },
};

export default SubscriptionRouteGuard;
