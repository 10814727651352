enum Actions {
  // action types
  FORGOT_PASSWORD = 'forgotPassword',
  FORGOT_PASSWORD_VERIFY = 'forgotPasswordVerify',
  RESET_PASSWORD = 'forgotPasswordReset',
}

enum Mutations {
  SET_FORGOT_PASSWORD_ACTION_ERROR = 'setForgotPasswordActionError',
}
// mutation types

enum Getters {
  GET_FORGOT_PASSWORD_ACTION_ERROR = 'getForgotPasswordActionError',
}

export { Actions, Mutations, Getters };
