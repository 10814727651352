enum Actions {
  FETCH_MAILING_LIST = 'fetchMailingList',
  FETCH_PLACEHOLDERS = 'fetchPlaceHolders',
  CREATE_TEMPLATE = 'createTemplate',
  UPDATE_TEMPLATE = 'updateTemplate',
  FETCH_TEMPLATE_LIST = 'fetchTemplateList',
  FETCH_TEMPLATE_BY_ID = 'fetchTemplateById',
  FETCH_TEMPLATE_BY_SYSTEM_NAME = 'fetchTemplateBySystemName',
  DELETE_TEMPLATE = 'deleteTemplate',
}

enum Mutations {
  SET_PLACEHOLDERS = 'setPlaceHolders',
  SET_MAILING_LIST = 'setMailingList',
  SET_TITLE = 'setTemplateTitle',
  SET_TEMPLATE = 'setTemplate',
  SET_TEMPLATES = 'setTemplates',
  SET_ERROR = 'setSettingError',
  SET_TEMPLATES_BY_SYSTEM_NAME = 'getTemplatesBySystemName',
}

enum Getters {
  GET_MAILING_LIST = 'getMailingList',
  GET_TITLE = 'getTemplateTitle',
  GET_TEMPLATE = 'getTemplate',
  GET_TEMPLATES = 'getTemplates',
  GET_ERROR = 'getSettingError',
  GET_PLACEHOLDERS = 'getPlaceHolders',
  GET_TEMPLATES_BY_SYSTEM_NAME = 'getTemplatesBySystemName',
}

export { Actions, Mutations, Getters };
