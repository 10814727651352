enum Actions {
  // action types
  VERIFY_ACCOUNT_TOKEN = 'accountVerificationToken',
  VERIFY_ACCOUNT_CODE = 'verifyAccountCode',
  RESEND_VERIFICATION_EMAIL = 'resendVerificationEmail',
  REQUEST_NEW_TOKEN = 'requestNewToken',
  // STEP_1 = 'accountUploadProfile',
  // STEP_2 = 'accountUploadLogo',
  // STEP_3 = 'accountBusinessProfileSubmit',
  // STEP_4 = 'accountAgentProfileSubmit',
  // STEP_5 = 'verifyAccount',
}

enum Mutations {
  SET_VALID_VERIFY_TOKEN = 'setAccountVerifiedToken',
  SET_VERIFICATION_AUTH_TOKEN = 'setAccountVerificationToken',
  SET_UPLOAD_PROFILE = 'setAccountProfile',
  SET_ACTION_ERROR = 'setVerifyAccountActionError',
}

enum Getters {
  GET_VERIFY_ACCOUNT_TOKEN = 'getAccountVerifiedToken',
  GET_ACTION_ERROR = 'getVerifyAccountActionError',
}

export { Actions, Mutations, Getters };
