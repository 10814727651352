import { App } from 'vue';
import timeUtil from '@/utils/time';
/**
 * Initialize Inline-Svg component
 * @param app vue instance
 */
export function initSystemFilters(app: App<Element>) {
  app.config.globalProperties.$filters = {
    formatRoleText(value) {
      if (value == 'pm') return 'Property Manager';
      if (value == 'principal') return 'Principal';
    },
    acronym(value) {
      const initials = value.match(/\b\w/g) || [];
      return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    },
    capitalize(text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
    ucwords(str) {
      return str.toLowerCase().replace(/\b[a-z]/g, function (letter) {
        return letter.toUpperCase();
      });
    },
    formatDate(value, format = 'aus') {
      return timeUtil.dateFormat(value, format);
    },
    formatACN(acn) {
      acn = acn ? acn.trim().replace(' ', '') : '';
      if (!acn) return '';

      if (acn.length === 11) return acn;

      const s1 = acn.slice(0, 3);
      const s2 = acn.slice(3, 6);
      const s3 = acn.slice(6);

      return `${s1} ${s2} ${s3}`;
    },
    formatABN(abn) {
      abn = abn ? abn.trim().replace(' ', '') : '';
      if (!abn) return '';

      if (abn.length === 14) return abn;

      const s1 = abn.slice(0, 2);
      const s2 = abn.slice(2, 5);
      const s3 = abn.slice(5, 8);
      const s4 = abn.slice(8);

      return `${s1} ${s2} ${s3} ${s4}`;
    },

    formatMobile(mobile) {
      mobile = mobile ? mobile.trim().replace(' ', '') : '';

      if (!mobile) return '';

      const s1 = mobile.slice(0, 4);
      const s2 = mobile.slice(4, 7);
      const s3 = mobile.slice(7);

      return `${s1} ${s2} ${s3}`;
    },

    formatPhone(phone) {
      phone = phone ? phone.trim().replace(' ', '') : '';

      const s1 = phone.slice(0, 2);
      const s2 = phone.slice(2, 6);
      const s3 = phone.slice(6);

      return `${s1} ${s2} ${s3}`;
    },

    formatMoney(amount, format = 'en-AU', currency = 'AUD') {
      return new Intl.NumberFormat(format, {
        style: 'currency',
        currency: currency,
      }).format(amount);
    },
  };
}
