enum Actions {
  // action types
  // countries
  LIST_ALL_COUNTRIES = 'listAllCountries',

  // timezones
  LIST_ALL_TIMEZONES = 'listAllTimezones',

  // states
  LIST_STATES = 'listStates',
}

enum Mutations {
  // mutation types
  // countries
  SET_ALL_COUNTRIES = 'setAllCountries',

  // timezones
  SET_ALL_TIMEZONES = 'setAllTimezones',

  // states
  SET_LIST_STATES = 'setListStates',
}

enum Getters {
  // countries
  GET_ALL_COUNTRIES = 'getCountries',

  // timezones
  GET_ALL_TIMEZONES = 'getTimezones',

  // states
  GET_LIST_STATES = 'getListStates',
}

export { Actions, Mutations, Getters };
