import ApiService from '@/core/services/ApiService';
import JwtService from '@/core/services/JwtService';
import CookieService from '@/core/services/CookieService';

import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { Mutations as UserMutations } from '@/store/enums/UserEnums';
import { Actions, Getters, Mutations } from '@/store/enums/AuthEnums';
import {
  Getters as StoreGetters,
  Actions as StoreActions,
  Mutations as StoreMutations,
} from '@/store/enums/StoreEnums';

import { AuthModuleStore, StoreError, SystemError } from '@/models/StoreModel';
import { Auth } from '@/models/AuthModel';
import router from '@/router';

@Module
export default class AuthModule extends VuexModule implements AuthModuleStore {
  routeError: StoreError | null = null;
  actionError: StoreError | null = null;
  systemError: SystemError | null = null;

  authUser = {} as unknown as Auth;
  bearerToken = JwtService.getToken();
  isAuthenticated = false;

  profilePicture = '';

  isVerifiedMobile = '' as any;

  /**
   * Get current user object
   * @returns user
   */
  get [Getters.GET_AUTH_USER](): Auth {
    return this.authUser;
  }

  /**
   * Get current user object
   * @returns user
   */
  get [Getters.GET_AUTH](): Auth {
    return this.isAuthenticated as unknown as Auth;
  }

  /**
   * Get current user object
   * @returns user
   */
  get [Getters.GET_AUTH_STATUS](): Auth {
    return this.isAuthenticated as unknown as Auth;
  }

  /**
   * Get current user object
   * @returns user
   */
  get [Getters.GET_AUTH_TOKEN](): any {
    return this.bearerToken;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get [Getters.GET_AUTH_ERROR](): StoreError {
    return this.actionError as StoreError;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get [Getters.GET_AUTH_ROUTE_ERROR](): StoreError {
    return this.routeError as StoreError;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get [StoreGetters.GET_SYSTEM_ERROR](): SystemError {
    return this.systemError as SystemError;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get [Getters.GET_AUTH_USER_ACTION_ERROR](): StoreError {
    return this.actionError as StoreError;
  }

  get [Getters.GET_AUTH_USER_PROFILE_PICTURE](): any {
    return this.profilePicture;
  }

  @Mutation
  [Mutations.SET_AUTH_USER_PROFILE_PICTURE](picture) {
    this.profilePicture = picture;
  }

  @Mutation
  [Mutations.SET_AUTH_ERROR](error) {
    this.actionError = error;
  }

  @Mutation
  [Mutations.SET_AUTH_USER_ACTION_ERROR](error) {
    this.actionError = error;
  }

  @Mutation
  [Mutations.SET_AUTH_ROUTE_ERROR](error) {
    this.routeError = error;
  }

  @Mutation
  [StoreMutations.SET_SYSTEM_ERROR](error) {
    this.systemError = error;
  }

  @Mutation
  [Mutations.SET_AUTH](data) {
    this.isAuthenticated = true;
    this.authUser = data.user;
    this.bearerToken = data.token;

    this.profilePicture = data.user.avatar_src;

    this.isVerifiedMobile =
      data.user.mobile_verified_at !== null ? true : false;

    JwtService.saveToken(data.token);
    CookieService.set('sessionToken', data.token);
    CookieService.set('authSession', 'true');
  }

  @Mutation
  [Mutations.SET_AUTH_TOKEN](token) {
    this.bearerToken = token;

    JwtService.saveToken(token);
    CookieService.set('sessionToken', token);
  }

  @Mutation
  [Mutations.SET_AUTH_STATUS](data) {
    this.isAuthenticated = data;
    this.actionError = null;
    if (!data) {
      this.context.dispatch(Actions.LOGOUT);
    }
  }

  @Mutation
  [Mutations.SET_AUTH_USER](user) {
    this.authUser = user;
    this.profilePicture = user.avatar_src;
    this.isVerifiedMobile = user.mobile_verified_at !== null ? true : false;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.authUser = {} as Auth;
    CookieService.remove('sessionToken');
    CookieService.remove('authSession');
    CookieService.remove('sessionValid');
    JwtService.destroyToken();
  }

  @Mutation
  [Mutations.SET_MOBILE_IS_VERIFIED](isVerified) {
    this.isVerifiedMobile = isVerified !== null ? true : false;
  }

  /**
   * Start new session
   * @param credentials
   * @returns void
   */
  @Action
  [Actions.REGISTER](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post('account/sign-up', payload)
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_AUTH_ERROR, response.data);
          reject();
        })
        .finally(() => {
          const severity = {
            error: 'System Error',
            message: 'The application encountered a fatal error.',
          };
          this.context.commit(StoreMutations.SET_SYSTEM_ERROR, severity);
        });
    });
  }

  /**
   * Start new session
   * @param credentials
   * @returns void
   */
  @Action
  [Actions.LOGIN](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post('account/sign-in', credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data.data);
          this.context.commit(UserMutations.SET_USER, data.data.user);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_AUTH_ERROR, response.data);
          reject();
        })
        .finally(() => {
          const severity = {
            error: 'System Error',
            message: 'The application encountered a fatal error.',
          };
          this.context.commit(StoreMutations.SET_SYSTEM_ERROR, severity);
        });
    });
  }

  /**
   * End session
   * @param
   * @returns void
   */
  @Action
  [Actions.LOGOUT]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get('account/logout')
        .then(() => {
          this.context.commit(Mutations.PURGE_AUTH);
          this.context.dispatch(
            StoreActions.ADD_BODY_CLASSNAME,
            'page-loading'
          );
          router.replace({ name: 'sign-in' });
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_AUTH_ERROR, response.data);
          reject();
        })
        .finally(() => {
          const severity = {
            error: 'System Error',
            message: 'The application encountered a fatal error.',
          };
          this.context.commit(StoreMutations.SET_SYSTEM_ERROR, severity);
        });
    });
  }

  /**
   * Retrieve auth user
   * @param
   * @returns void
   */
  @Action
  [Actions.FETCH_AUTH_USER]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get('account/user')
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH_USER, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_AUTH_USER_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }

  /**
   * Verify bearer token if valid
   * @param payload
   * @returns
   */
  @Action
  [Actions.VERIFY_AUTH]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get('validate-token')
        .then(async (data) => {
          this.context.commit(Mutations.SET_AUTH_STATUS, data.data.valid);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_AUTH_USER_ACTION_ERROR, response);
          reject();
        });
    });
  }

  /**
   * Clear auth user
   * @param
   * @returns
   */
  @Action
  [Actions.CLEAR_AUTH_USER]() {
    return new Promise<void>((resolve) => {
      this.context.commit(Mutations.SET_AUTH_USER, {});
      resolve();
    });
  }

  @Action
  [Actions.SUBMIT_COMPLETE_PROFILE](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post('account/profile', payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH_USER, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_AUTH_USER_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_USER_PROFILE](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put('account/profile', payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH_USER, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_AUTH_USER_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }

  @Action
  [Actions.NEW_PASSWORD](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post('account/user/password', payload)
        .then(() => resolve())
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_AUTH_USER_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }

  @Action
  [Actions.CHANGE_PASSWORD](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put('account/user/password', payload)
        .then(() => resolve())
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_AUTH_USER_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }

  @Action
  [Actions.SEND_MOBILE_CODE](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post('account/mobile', payload)
        .then(() => resolve())
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_AUTH_USER_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }

  @Action
  [Actions.VERIFY_MOBILE](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post('account/mobile/verify', payload)
        .then(() => {
          // this.context.dispatch(Actions.FETCH_AUTH_USER);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_AUTH_USER_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }

  @Action
  [Actions.UPLOAD_PROFILE_PICTURE](payload) {
    return new Promise<void>((resolve, reject) => {
      const form = new FormData();
      form.append('image', payload);

      ApiService.post('account/profile/picture', form, {})
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_AUTH_USER_PROFILE_PICTURE,
            data.data.avatar_src
          );

          this.context.commit(Mutations.SET_AUTH_USER, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_AUTH_USER_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }

  // @Action
  // [Actions.PULL_USER_PROFILE_PICTURE]() {
  //   this.context.commit(Mutations.SET_AUTH_USER_PROFILE_PICTURE, null);

  //   return new Promise<void>((resolve, reject) => {
  //     ApiService.get('account/profile/picture', '', {
  //       responseType: 'arraybuffer',
  //     })
  //       .then(({ data }) => {
  //         const imageData = image.rawImageToDataUrl(data, 'jpg');
  //         this.context.commit(
  //           Mutations.SET_AUTH_USER_PROFILE_PICTURE,
  //           imageData
  //         );
  //         resolve(data);
  //       })
  //       .catch(({ response }) => {
  //         const errorResponse = {
  //           errors: [],
  //           message: response.statusText,
  //           status: response.status,
  //         };
  //         this.context.commit(
  //           Mutations.SET_AUTH_USER_ACTION_ERROR,
  //           errorResponse
  //         );
  //         reject(response);
  //       });
  //   });
  // }

  @Action
  [Actions.REMOVE_PROFILE_PICTURE](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put('account/profile/picture/remove', payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH_USER_PROFILE_PICTURE, '');
          this.context.commit(Mutations.SET_AUTH_USER, data.data);

          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_AUTH_USER_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }
}
