enum Actions {
  FETCH_PLANS = 'fetchPlans',
  FETCH_PLAN = 'fetchPlan',
  FETCH_PLAN_PRODUCT = 'fetchPlanProduct',
}

enum Mutations {
  SET_PLAN = 'setPlan',
  SET_PLANS = 'setPlans',
  SET_PLAN_PRODUCT = 'setPlanProduct',
  SET_ACTION_ERROR = 'setPlanActionError',
}

enum Getters {
  GET_PLAN = 'getPlan',
  GET_PLANS = 'getPlans',
  GET_PLAN_PRODUCT = 'getPlanProduct',
  GET_ACTION_ERROR = 'getPlanActionError',
}

export { Actions, Mutations, Getters };
